import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Category from './Category'

const Menu = () => {
    const data = useStaticQuery(graphql`
        query MenuData {
          dataYaml {
            categories {
              displayName
              description
              extra
              items {
                name
                description
                price
              }
            }
          }
        }
    `)

    const { categories } = data.dataYaml
    return (
        <div>
            {categories.map((category, key) => (
                <Category key={key} data={category} />
            ))}
        </div>
    )
}

export default Menu

Menu.propTypes = {

}
