import React from 'react'
import PropTypes from "prop-types"

const Category = ({ data }) => {
    const { displayName, description, extra, items } = data

    let menu = null
    if (items) {
        menu = <div className={'menu--items'}>
            {items.map((item, key) => (
                <div key={key} className={'menu--item'}>
                    <div className={'item--name'}>
                        { item.name } <span>{item.description}</span>
                    </div>
                    <div className={'item--price'}>
                        { item.price }$
                    </div>

                </div>
            ))}
        </div>
    }

    return (
        <div className={'menu--category'}>
            <h3>{ displayName }</h3>
            { description && <p className={'menu--description'}>{ description }</p>}
            { menu }
            { extra && <i className={'menu--extra'}>* { extra }</i>}
        </div>
    )
}

export default Category

Category.propTypes = {
    data: PropTypes.shape({
        displayName: PropTypes.string,
        description: PropTypes.string,
        extra: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            description: PropTypes.string,
            price: PropTypes.number
        })
        )
    })
}
